@use '@/styles/typography.module';
@use '@/styles/button.module';
@use '@/styles/contexts.module';
@import '@/styles/breakpoints.module';

.cardsContainer {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 8px;

  @include tablet {
    grid-gap: 16px;
  }
  @include desktop {
    display: flex;
    justify-content: space-between;
    gap: 0;
    & > .card:not(:last-child) {
      margin-inline-end: 8px;
    }
  }
}

.card {
  padding: 8px;
  background-color: var(--color-card-bg);
  border-radius: var(--border-radius-card);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  box-shadow: 0px 0px 30px 0px rgba(11, 11, 11, 0.4);
  width: 100%;

  &:hover {
    background-color: var(--palette-primary-700);
    --color-btn-link-text: var(--palette-white);
    --color-btn-link-hover-text: var(--palette-white);
    --color-btn-link-active-text: var(--palette-white);
    --color-heading: var(--palette-white);
    --color-text-secondary: var(--palette-white);
    .cardIconLight {
      display: inline;
    }
    .cardIcon {
      display: none;
    }
  }

  @include tablet {
    padding: 24px;
  }

  @include desktop {
    padding: 24px 12px;
    max-width: 264px;
  }
}

.cardIconContainer {
  margin-bottom: 4px;

  svg,
  img {
    max-width: 40px;
    width: 100%;
    height: auto;
  }

  @include tablet {
    margin-bottom: 16px;
    svg,
    img {
      max-width: 80px;
      width: 100%;
      height: auto;
    }
  }
}

.cardTitle {
}

.cardDescription.cardDescription {
  display: none;
  margin-top: 8px;
  @include tablet {
    display: block;
  }
}

.cardAction.cardAction {
  margin-top: 4px;
  @include tablet {
    margin-top: 16px;
  }
}

.actionContainer {
  @include button.btnS_;
  @include tablet {
    @include button.btnM_;
  }
}

.actionTextMobile {
  @include tablet {
    display: none;
  }
}

.actionText {
  display: none;
  @include tablet {
    display: inline;
  }
}

.cardBody {
  text-align: center;

  @include tablet {
    max-width: 288px;
  }

  @include desktop {
    max-width: 216px;
  }
}

.cardIcon {
}

.cardIconLight {
  display: none;
}
