@import '@/styles/breakpoints.module';

.indexMobileSection {
  margin-top: 80px;
  @include tablet {
    margin-top: 96px;
  }
  @include desktop {
    margin-top: 119px;
  }
}
