.light {
  --color-heading: var(--palette-gray-900);
  --color-subtitle: var(--palette-gray-600);
  --color-text-secondary: var(--palette-gray-600);
  --color-text-primary: var(--palette-gray-900);
  --color-accent-heading-fragment: var(--palette-primary-700);

  --color-btn-outline-text: var(--palette-primary-700);
  --color-btn-outline-border: var(--palette-primary-700);
  --color-btn-outline-bg: transparent;

  --color-btn-outline-hover-border: var(--palette-primary-700);
  --color-btn-outline-hover-bg: var(--palette-primary-700);
  --color-btn-outline-hover-text: var(--palette-white);

  --color-btn-outline-active-text: var(--palette-primary-500);
  --color-btn-outline-active-border: var(--palette-primary-500);
  --color-btn-outline-active-bg: transparent;

  --color-btn-link-text: var(--palette-primary-700);
  --color-btn-link-hover-text: var(--palette-primary-800);
  --color-btn-link-active-text: var(--palette-primary-500);

  --color-table-cell-text: var(--palette-gray-500);
  --color-table-heading-text: var(--palette-gray-400);
  --color-table-hover-cell-text: var(--palette-white);
  --color-table-row-bg: var(--palette-white);
  --color-table-stripe-row-bg: var(--palette-white);

  // tabs
  --color-tab-text: var(--palette-gray-300);
  --color-tab-border: var(--palette-gray-100);
  --color-tab-active-text: var(--palette-primary-700);
  --color-tab-hover-text: var(--palette-primary-700);
  --color-tab-active-border: var(--palette-primary-700);

  // cards
  --color-benefit-static-card-bg: var(--palette-gray-50);

  --color-service-card-bg: var(--palette-gray-70);
  --color-service-card-hover-bg: var(--palette-gray-150);

  --color-trend-negative: var(--palette-red-600);
  --color-trend-positive: var(--palette-green-600);

  // slider
  --slider-pagination-bullet-color: var(--palette-gray-200);
  --slider-pagination-bullet-active-color: var(--palette-primary-700);
  --slider-pagination-bullet-opacity: 1;
  --slider-chevron-color: var(--palette-gray-300);
  --slider-chevron-hover-color: var(--palette-gray-200);
  --slider-chevron-active-color: var(--palette-gray-200);
}

.dark {
  --color-trend-negative: var(--palette-red-400);
  --color-trend-positive: var(--palette-green-400);

  --color-heading: var(--palette-white);
  --color-subtitle: var(--palette-gray-200);
  --color-text-secondary: var(--palette-gray-200);
  --color-text-primary: var(--palette-white);
  --color-accent-heading-fragment: var(--color-text-primary);
  // primary btn
  --color-btn-primary-shadow: var(--palette-primary-700-50);
  --color-btn-primary-border: var(--palette-gray-900);
  --color-btn-primary-bg: var(--palette-primary-700);
  --color-btn-primary-text: var(--palette-white);
  --color-btn-primary-hover-bg: var(--palette-primary-800);
  --color-btn-primary-active-bg: var(--palette-primary-900);
  // filled btn
  --color-btn-filled-border: var(--palette-primary-700-50);
  --color-btn-filled-bg: var(--palette-white);
  --color-btn-filled-text: var(--palette-primary-700);
  --color-btn-filled-hover-bg: var(--palette-primary-700);
  --color-btn-filled-hover-text: var(--palette-white);
  --color-btn-filled-active-bg: var(--palette-white);
  --color-btn-filled-active-border: var(--palette-primary-300);
  --color-btn-filled-active-text: var(--palette-primary-500);
  // outline btn
  --color-btn-outline-text: var(--palette-white);
  --color-btn-outline-border: var(--palette-white);
  --color-btn-outline-bg: transparent;
  --color-btn-outline-hover-border: var(--palette-primary-700);
  --color-btn-outline-hover-bg: var(--palette-primary-700);
  --color-btn-outline-hover-text: var(--palette-white);
  --color-btn-outline-active-text: var(--palette-primary-300);
  --color-btn-outline-active-border: var(--palette-primary-300);
  --color-btn-outline-active-bg: transparent;
  // text btn
  --color-btn-link-text: var(--palette-white);
  --color-btn-link-hover-text: var(--palette-primary-500);
  --color-btn-link-active-text: var(--palette-primary-300);
  --border-radius-cta-block-container: var(--palette-border-radius-l);
  --border-radius-sections-group: var(--palette-border-radius-m);
  --border-radius-card: var(--palette-border-radius-s);
  --padding-card: var(--palette-border-radius-s);
  --color-card-bg: var(--palette-white);
  // table
  --color-table-heading-text: var(--palette-gray-200);
  --color-table-cell-text: var(--palette-white);
  --color-table-row-bg: var(--palette-gray-800);
  --color-table-stripe-row-bg: var(--palette-gray-900);

  // tabs
  --color-tab-text: var(--palette-white);
  --color-tab-border: transparent;
  --color-tab-active-text: var(--palette-white);
  --color-tab-hover-text: var(--palette-white);
  --color-tab-active-border: var(--palette-primary-700);

  // cards
  --color-benefit-static-card-bg: var(--palette-gray-900);
  --color-banner-bg: var(--palette-gray-700);
}
